import React from "react";
import loader_icon from "../assets/images/favicon.png";
import { useSelector } from "react-redux";

const Loader = () => {
  const { loading } = useSelector((state) => state.loaderReducer);
  return (
    <>
      {loading === true ? (
        <div className="loader-holder">
          <img
            src={loader_icon}
            className="img-fluid grow"
            alt=""
            loading="lazy"
          />
        </div>
      ) : null}
    </>
  );
};

export default Loader;
