import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import I18n from "redux-i18n";

import Router from "./Route";
import translation from "./constants/translation";
import configureStore from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={configureStore()}>
    <I18n
      translations={translation}
      initialLang={localStorage.getItem("lang") == "ar" ? "ar" : "en"}
    >
      <Router />
    </I18n>
  </Provider>
  // </React.StrictMode>
);

