import { createStore, applyMiddleware,compose } from "redux";
import thunkMiddleware from "redux-thunk";
import reducers from "./reducer";

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const middleware = composeEnhancers(applyMiddleware(thunkMiddleware));

const configureStore = state => {
  return createStore(reducers, state, middleware);
};

export default configureStore;
