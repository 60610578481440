import React, { useEffect, Suspense, lazy } from "react";
import PropTypes from "prop-types";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "bootstrap/dist/js/bootstrap.min.js";
import "aos/dist/aos.css";
import "./assets/scss/main.scss";
import "./assets/custom.css";
import Loader from "./components/Loader";
import { useSelector } from "react-redux";

const Home = lazy(() => import("./pages/Home"));
const Contact = lazy(() => import("./pages/Contact"));
const Gallery = lazy(() => import("./pages/Gallery"));
const Services = lazy(() => import("./pages/Services"));
const NotFound = lazy(() => import("./components/NotFound"));

const RouterComponent = (props, context) => {
  const [{ lang }] = useSelector((state) => [state.i18nState]);

  useEffect(() => {
    AOS.init();
    addHtmlAttr(lang);
  }, [lang]);

  const addHtmlAttr = (language) => {
    document.querySelector("html")?.setAttribute("lang", language);
    document
      .querySelector("html")
      ?.setAttribute("dir", language === "ar" ? "rtl" : "ltr");
  };

  return (
    <>
      <Loader />
      <div>
        <Suspense fallback={<Loader />}>
          <BrowserRouter basename="">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/gallery/:id" element={<Gallery />} />
              <Route path="/services/:id" element={<Services />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </div>
    </>
  );
};

export default RouterComponent;

RouterComponent.contextTypes = {
  t: PropTypes.func,
};
