import { SHOW_LOADER, HIDE_LOADER } from "../constants";

const initialState = {
    loading: false,
    content:""
};

const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOADER:
            return {
                ...state,
                loading: action.payload,
                content: action.content
            };
        case HIDE_LOADER:
            return {
                ...state,
                loading: action.payload,
                content: "",
            };
        default:
            return { ...state };
    }
};

export default loaderReducer;